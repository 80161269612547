import Image from 'next/image';
import { FC, memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import LogoSSR from 'components/Headers/components/LogoSSR';
import LandingHeader from 'components/Headers/LandingHeader';
import LandingFooter from 'components/LandingFooter';
import LanguageSelect, { LanguagePickerVariant } from 'components/LanguageSelect';
import Hidden from 'components/ui/Hidden';
import { Burger } from 'components/ui/icons/Common';
import { LINKS } from 'constants/constants';
import { IconWrapper } from 'containers/LandingContainer/styed';
import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen';
import { useTranslationEditorContext } from 'providers/TranslationEditor';
import { breakpoints } from 'styles';
import { canonicalLink } from 'utils/seo';

import {
  Container,
  LandingContainer,
  Content,
  LandingBlueBlock,
  LoginButton,
  PopUpWrapper,
  PopUpContainer,
  PopUpBackground,
  PopUpMenuContainer,
  PopUpMenu,
  PopUpHeader,
  PopUpContent,
  LandingContentContainer,
  MenuLinkWrapper,
  MenuLink,
} from './styled';

const closeIcon = '/icons/landing/closeIconMenu.svg';

type LandingLayoutProps = {
  children: ReactNode;
  noBGImage?: boolean;
  keepWidth?: boolean;
  isLogged?: boolean;
  hideLogin?: boolean;
  hideMenuIcon?: boolean;
  backgroundColor?: string;
  heightAuto?: boolean;
  customHeader?: React.ReactNode;
  showLanguagePicker?: boolean;
  showFooterLogin?: boolean;
  hideMobileNav?: boolean;
  hideFooter?: boolean;
  facelift?: boolean;
  miniGenerator?: boolean;
  qrDownload?: boolean;
};

const LandingLayout: FC<LandingLayoutProps> = ({
  children,
  noBGImage = false,
  keepWidth = false,
  backgroundColor,
  isLogged = false,
  hideLogin = false,
  hideMenuIcon = false,
  heightAuto,
  customHeader,
  showLanguagePicker = true,
  showFooterLogin = false,
  hideMobileNav = false,
  hideFooter = false,
  facelift = false,
  miniGenerator = false,
  qrDownload = false,
}) => {
  const { t, i18n } = useTranslation();
  const { getDataAttributes } = useTranslationEditorContext();
  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
  const [footerType, setFooterType] = useState('default');

  const keys = useMemo(
    () => ({
      login: 'qr.page.header.login',
      register: 'qr.page.header.register',
      contactUs: 'qr.auth.contact.us',
      faq: 'qr.auth.faq',
      prices: 'qr.auth.privacy.prices',
      termsOfUse: 'qr.auth.terms.and.conditions',
      policy: 'qr.auth.privacy.policy',
    }),
    []
  );

  useEffect(() => {
    if (
      window.location.href.includes('sign-up') ||
      window.location.href.includes('pricing') ||
      window.location.href.includes('plans') ||
      window.location.href.includes('card') ||
      window.location.href.includes('login') ||
      window.location.href.includes('recover-password') ||
      window.location.href.includes('checkout/success')
    ) {
      setFooterType('liteFooter');
    } else {
      setFooterType('default');
    }
  }, []);

  const text = useMemo(
    () => ({
      login: t(keys.login, 'Log in'),
      register: t(keys.register, 'Register'),
      contactUs: t(keys.contactUs, 'Contact us'),
      faq: t(keys.faq, 'FAQ'),
      prices: t(keys.prices, 'Prices'),
      termsOfUse: t(keys.termsOfUse, 'Terms of Use'),
      policy: t(keys.policy, 'Privacy Policy'),
    }),
    [keys, t, i18n.language]
  );

  const menuMobile = useMemo(() => {
    return [
      {
        name: text.contactUs,
        textKey: keys.contactUs,
        link: LINKS.CONTACT_US,
      },
      {
        name: text.faq,
        textKey: keys.faq,
        link: LINKS.FAQ,
      },
      {
        name: text.prices,
        textKey: keys.prices,
        link: LINKS.PRICES,
      },
      {
        name: text.termsOfUse,
        textKey: keys.termsOfUse,
        link: LINKS.TERMS,
      },
      {
        name: text.policy,
        textKey: keys.policy,
        link: LINKS.POLICY,
      },
    ];
  }, [keys, i18n.language]);

  const { isTablet } = useCheckMobileScreen({ tableWidth: breakpoints.tabletL });

  useEffect(() => {
    if (!isTablet) {
      setIsPopUpOpen(false);
    }
  }, [isTablet]);

  const exitTimeout = isTablet ? 300 : 0;

  const menuMobileContent = menuMobile.map(({ name, textKey, link }) => (
    <MenuLinkWrapper key={name}>
      <MenuLink href={link} {...getDataAttributes(textKey)}>
        {name}
      </MenuLink>
    </MenuLinkWrapper>
  ));

  const onBurgerClick = useCallback((): void => {
    setIsPopUpOpen(true);
  }, []);

  const onCloseClick = useCallback((): void => {
    setIsPopUpOpen(false);
  }, []);

  const header = (
    <LandingContentContainer>
      <LandingHeader
        linkTo={isLogged ? LINKS.CABINET_QR_CODES : canonicalLink('', false, i18n.language)}
        showLanguagePicker={showLanguagePicker}
        facelift={facelift}
        miniGenerator={miniGenerator}
        qrDownload={qrDownload}
      >
        <>
          {!isLogged && !hideLogin ? (
            <Hidden mdDown>
              <LoginButton
                href={LINKS.LOGIN}
                {...getDataAttributes(keys.login)}
                style={{ textDecoration: 'none' }}
                facelift={facelift}
              >
                {text.login}
              </LoginButton>
            </Hidden>
          ) : null}
          {!(hideMobileNav && isLogged) && (
            <Hidden mdUp>
              <IconWrapper data-testid="burger-menu" onClick={onBurgerClick}>
                <Burger />
              </IconWrapper>
            </Hidden>
          )}
        </>
      </LandingHeader>
    </LandingContentContainer>
  );

  return (
    <>
      <style>{isPopUpOpen ? `html;,body {overflow: hidden;}` : `body {overflow:auto;}`}</style>
      <Container backgroundColor={backgroundColor} noBGImage={noBGImage} heightAuto={heightAuto}>
        <LandingContainer>
          <Content keepWidth={keepWidth}>
            {customHeader || header}
            {children}
          </Content>
        </LandingContainer>
        <LandingBlueBlock id="landing-blue-block" />
      </Container>
      {!hideFooter && (
        <LandingContainer>
          <LandingContentContainer>
            <LandingFooter
              footerType={footerType}
              noBGImage={noBGImage}
              logoLinkTo={canonicalLink('', false, i18n.language)}
              showLanguagePicker={showLanguagePicker}
              showFooterLogin={showFooterLogin}
            />
          </LandingContentContainer>
        </LandingContainer>
      )}
      {!(hideMobileNav && isLogged) && (
        <CSSTransition
          in={isPopUpOpen}
          timeout={{ enter: 0, exit: exitTimeout }}
          classNames="popupTransition"
          unmountOnExit
          appear
        >
          <PopUpWrapper>
            <PopUpBackground onClick={onCloseClick} />
            <PopUpContainer>
              <CSSTransition in={isPopUpOpen} timeout={0} classNames="menuTransition" appear>
                <PopUpMenuContainer isPopUpOpen={isPopUpOpen}>
                  <PopUpHeader>
                    <LogoSSR />
                    <Image onClick={onCloseClick} src={closeIcon} width={30} height={30} alt="close" priority />
                  </PopUpHeader>
                  {showLanguagePicker && (
                    <LanguageSelect isCompact={false} pickerType={LanguagePickerVariant.Dropdown} />
                  )}
                  <PopUpContent>
                    {!hideMobileNav && <PopUpMenu>{menuMobileContent}</PopUpMenu>}
                    {!isLogged && !hideLogin && (
                      <LoginButton href={LINKS.LOGIN} className="menuLoginButton" {...getDataAttributes(keys.login)}>
                        {text.login}
                      </LoginButton>
                    )}
                  </PopUpContent>
                </PopUpMenuContainer>
              </CSSTransition>
            </PopUpContainer>
          </PopUpWrapper>
        </CSSTransition>
      )}
    </>
  );
};

export default memo(LandingLayout);
